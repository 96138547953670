.form-master {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  align-items: center;
  margin: 0 auto 10vh auto;
  color: #363636;
  background-color: white;
  padding: 2rem 1rem;
  box-shadow: 0 0 20px lightgray;
  border-radius: 10px;
  min-height: 58vh;
}

@media (max-width: 600px) {
  .form-master {
    width: 85%;
    padding: 2rem 5%;
  }
}

.form-control {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  max-width: 100%;
}

.form-control input {
  font-size: 1.5rem;
  width: 100%;
  height: 3rem;
  border-style: none;
  color: #525252;
  text-align: center;
  background-color: #edf6f9;
  box-shadow: inset 0 0 2px;
  border-radius: 4px;
}

.error {
  outline: 1px solid #e37262;
}

.error-message {
  color: #e37262 !important;
}

.error-message::before {
  content: "*";
}

.form-control input::placeholder {
  color: rgba(189, 189, 189, 0.8);
}

.form-control input:focus {
  box-shadow: 0 0 8px #3fb39d;
  outline: none;
}

.form-label {
  text-align: center;
  font-size: 1.5rem;
  margin: 0.5rem;
  color: #363636;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.legal-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-select {
  font-size: 1.5rem;
  border-style: none;
  height: 3rem;
  width: 100%;
  margin: auto;
  padding: 0 1rem;
  background-color: #edf6f9;
  box-shadow: inset 0 0 2px;
  border-radius: 4px;
  color: #525252;
}

.form-select:focus {
  box-shadow: 0 0 8px #3fb39d;
  outline: none;
}

.form-button-group {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
}

.form-button {
  border-style: none;
  height: 2.5rem;
  background-color: #3cb09b;
  min-width: 40%;
  margin: 0.5rem;
  padding: 0 1rem;
  color: white;
  border-color: #363636;
  box-shadow: 0 0 3px lightgray;
  border-radius: 5px;
  font-size: 1.3rem;
}

.form-button:hover {
  background-color: #334d5d;
  cursor: pointer;
}

.form-button:focus {
  box-shadow: 0 0 8px #3fb39d;
  outline: none;
}

.progress {
  height: 0.7rem;
  width: 70%;
  -webkit-appearance: none;
  appearance: none;
}

.progress::-webkit-progress-bar {
  background-color: white;
  border: 1px solid #3fb39d;
  border-radius: 20px;
}

.progress::-webkit-progress-value {
  background-color: #3fb39d;
  border-radius: 20px;

  transition: width 0.4s;
}

.progress::-moz-progress-bar {
  transition: padding-bottom 1s;
  padding-bottom: var(--value);
  transform-origin: 0 0;
  transform: rotate(-90deg) translateX(-15px);
  padding-left: 15px;
}

.form-top-group {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-nav {
  font-size: 2rem;
  color: #334d5d;
}

.form-nav:hover {
  color: #3fb39d;
  cursor: pointer;
}

/* Single Page Form Styling */

.single-page-form {
  width: 70%;
  margin: 0 auto;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 0 4px lightgrey;
}

.single-button-container {
  margin: 2rem auto;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .single-page-form {
    width: 85%;
  }
  .single-button-container {
    width: 100%;
  }
}

.single-select {
  min-width: 630px;
}

@media (max-width: 930px) {
  .single-select {
    min-width: 0px;
  }
}

.formerror {
  background-color: #e37262 !important;
}

.formerror:hover {
  background-color: #c95f51 !important;
  box-shadow: none;
}

.formerror:focus {
  box-shadow: none;
}
