.disclaimer-tooltip {
  width: 50vw;

  @media screen and (max-width: 600px) {
    width: 50vw;
    margin: 0 auto;
  }
}

.advertiser {
  color: black !important;
  background-color: white !important;
  width: 400px;
  font-size: 1rem;
  opacity: 1 !important;
  box-shadow: 0 0 4px lightgray;
}

@media (max-width: 600px) {
  .advertiser {
    width: 70vw !important;
  }
}

.more-info {
  color: gray;
}

.pre {
  font-size: 1.2rem;
  margin: 0 0.3rem;
}

.nav-arrow {
  font-size: 5rem;
  color: #334d5d;
}
.nav-arrow:hover {
  color: #3fb39d;
}

@media screen and (max-width: 515px) {
  .hide-nav {
    display: none;
  }
}

.disclaimer-info {
  color: #4c6f85;
  margin-left: 0.3rem;
}

.disclaimer-info:hover {
  cursor: pointer;
  color: #334d5d;
}
