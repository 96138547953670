.nav-link {
  text-decoration: none;
  color: #334d5d;
  font-size: 1.2rem;
}

.nav-link:hover {
  color: #3fb39d;
  cursor: pointer;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.language-icon {
  color: #eef7ef;
  margin-right: 1rem;
}
