.disclosure {
  width: 400px;
  background-color: white !important;
  color: black !important;
}

.disclosure-link {
  color: #3fb39d;
  text-decoration: underline;
}

.privacy-table {
  width: 100%;
}

.privacy-head th {
  background-color: #3fb39d;
  text-align: center;
  border-bottom: 1px solid black;
  color: white;
}

.privacy-table td {
  background-color: rgb(243, 243, 243);
  text-align: center;
  color: black;
  border: 1px solid gray;
}
