.disclaimer-tooltip {
  width: 30vw;

  @media screen and (max-width: 600px) {
    width: 50vw;
    margin: 0 auto;
  }
}

.advertiser {
  color: black !important;
  background-color: white !important;
  width: 400px;
  font-size: 1rem;
  opacity: 1 !important;
  box-shadow: 0 0 4px lightgray;
}

@media (max-width: 600px) {
  .advertiser {
    width: 70vw !important;
  }
}

.more-info {
  color: #334d5d;
}

.more-info:hover {
  cursor: pointer;
}

.emphasis {
  color: #3fb39d;
  text-decoration: underline;
}

.sort-container {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}
.sort-label {
  text-align: center;
  font-size: 1.1rem;
  margin: 0.5rem;
  color: #363636;
}
.sort-select {
  font-size: 1.1rem;
  border-style: none;
  height: 2rem;
  width: max-content;
  padding: 0 1rem;
  background-color: #edf6f9;
  box-shadow: inset 0 0 2px;
  border-radius: 4px;
  color: #525252;
}

@media (max-width: 750px) {
  .sort-container { 
    width: 90%;
    margin: 1rem auto;
    justify-content: center;
  }
}

.pending-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60%;
  margin: 1rem auto;
  top: 2rem;
  border-radius: 10px;
  align-items: center;
  background-color: white;
  padding: 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-size: 1.5rem;
}

.pending-container p {
  color: #3fb39d;
  margin-left: 1rem;
}

.pending-icon {
  margin-right: 1rem;
  color: #334d5d
}
