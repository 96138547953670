* {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -webkit-appearance: none;
}

html,
body {
  overflow-x: hidden;
}

textarea,
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="checkbox"] {
  -webkit-appearance: none;
  border-radius: 0;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
