.faq-icon {
  margin-left: 1rem;
  vertical-align: middle;
}
.down {
  color: #3fb39d;
}
.up {
  color: #e37262;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
