.modal-info-icon {
  color: #3cb09b;
  margin-right: 0.3rem;
}

.modal-close-x {
  color: gray;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: right;
}

.modal-close-x:hover {
  color: black;
  cursor: pointer;
}
